<template>
  <div class="admin appointments">

    <header class="admin-header cf">
      <h1>
        <p>Profil bearbeiten</p>
      </h1>
    </header>

    <div v-if="loading" class="loading-wrap">
      <span class="loading-spinner"></span>
    </div>
    <div v-else>

      <div class="content-wrap">
        <h3>Ihre Daten</h3>

        <div class="row row-gutter-20" style="margin-bottom:10px">
          <div class="col-12">
            <div class="form-wrap">
              <label for="first_name" class="form-label">Vorname</label>
              <input v-model="user.first_name" class="form-input input-grey" type="text" name="first_name"
                id="first_name">
            </div>
          </div>
          <div class="col-12">
            <div class="form-wrap">
              <label for="last_name" class="form-label">Nachname</label>
              <input v-model="user.last_name" class="form-input input-grey" type="text" name="last_name" id="last_name">
            </div>
          </div>
        </div>

        <div class="row row-gutter-20">
          <div class="col-12">
            <div class="form-wrap">
              <label for="phone_number" class="form-label">Telefon</label>
              <input v-model="user.phone_number" class="form-input input-grey" type="text" name="phone_number"
                id="phone_number">
            </div>
          </div>
          <div class="col-12">
            <div class="form-wrap">
              <label for="email" class="form-label">E-Mail</label>
              <input v-model="user.email" class="form-input input-grey" type="text" name="email" id="email">
            </div>
          </div>
        </div>

        <div class="form-wrap">
          <label for="email" class="form-label">Sprache</label>
          <select v-model="user.language" class="form-input">
            <option key="de" value="de">Deutsch</option>
            <option key="en" value="en">English</option>
          </select>
        </div>

        <p style="margin-bottom:0;text-align:right">
          <a @click="update_user" class="button button-red">
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="floppy-disk"
              class="svg-inline--fa fa-floppy-disk" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <path fill="currentColor"
                d="M433.1 129.1l-83.9-83.9C342.3 38.32 327.1 32 316.1 32H64C28.65 32 0 60.65 0 96v320c0 35.35 28.65 64 64 64h320c35.35 0 64-28.65 64-64V163.9C448 152.9 441.7 137.7 433.1 129.1zM224 416c-35.34 0-64-28.66-64-64s28.66-64 64-64s64 28.66 64 64S259.3 416 224 416zM320 208C320 216.8 312.8 224 304 224h-224C71.16 224 64 216.8 64 208v-96C64 103.2 71.16 96 80 96h224C312.8 96 320 103.2 320 112V208z">
              </path>
            </svg>
            <span>Daten speichern ›</span>
          </a>
        </p>

      </div>

      <div class="content-wrap">
        <h3>Passwort ändern</h3>

        <div class="row row-gutter-20" style="margin-bottom:10px">
          <div class="col-12">
            <div class="form-wrap">
              <label for="first_name" class="form-label">Neues Passwort</label>
              <input v-model="password" class="form-input input-grey" type="password" name="first_name" id="first_name">
            </div>
          </div>
          <div class="col-12">
            <div class="form-wrap">
              <label for="last_name" class="form-label">Neues Passwort wiederholen</label>
              <input v-model="password_confirmation" class="form-input input-grey" type="password" name="last_name"
                id="last_name">
            </div>
          </div>
        </div>

        <p style="margin-bottom:0;text-align:right">
          <a @click="update_password" class="button button-red">
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="floppy-disk"
              class="svg-inline--fa fa-floppy-disk" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <path fill="currentColor"
                d="M433.1 129.1l-83.9-83.9C342.3 38.32 327.1 32 316.1 32H64C28.65 32 0 60.65 0 96v320c0 35.35 28.65 64 64 64h320c35.35 0 64-28.65 64-64V163.9C448 152.9 441.7 137.7 433.1 129.1zM224 416c-35.34 0-64-28.66-64-64s28.66-64 64-64s64 28.66 64 64S259.3 416 224 416zM320 208C320 216.8 312.8 224 304 224h-224C71.16 224 64 216.8 64 208v-96C64 103.2 71.16 96 80 96h224C312.8 96 320 103.2 320 112V208z">
              </path>
            </svg>
            <span>Daten speichern ›</span>
          </a>
        </p>
      </div>

      <div class="content-wrap">
        <h3>Arbeitszeiten</h3>
        <div v-if="working_hours && working_hours.length == 0" class="alert alert-info" style="margin-bottom:0">
          <p>Noch keine Arbeitszeiten hinterlegt.</p>
        </div>
        <div v-else>
          <ul class="content-list">
            <li v-for="hour in working_hours" v-bind:key="hour.id">
              <span v-if="hour.working_day == 'monday'">Montag:</span>
              <span v-if="hour.working_day == 'tuesday'">Dienstag:</span>
              <span v-if="hour.working_day == 'wednesday'">Mittwoch:</span>
              <span v-if="hour.working_day == 'thursday'">Donnerstag:</span>
              <span v-if="hour.working_day == 'friday'">Freitag:</span>
              <span v-if="hour.working_day == 'saturday'">Samstag:</span>
              <span v-if="hour.working_day == 'sunday'">Sonntag:</span>
              {{ hour.start_formatted }} Uhr – {{ hour.end_formatted }} Uhr

              <svg @click="delete_hour(hour)" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="xmark"
                class="svg-inline--fa fa-xmark" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                <path fill="currentColor"
                  d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z">
                </path>
              </svg>
            </li>
          </ul>
        </div>
        <p style="text-align:right;margin-bottom:0">
          <a @click="modal_add_working_hour = true" class="button button-red">
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="plus" class="svg-inline--fa fa-plus"
              role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <path fill="currentColor"
                d="M432 256c0 17.69-14.33 32.01-32 32.01H256v144c0 17.69-14.33 31.99-32 31.99s-32-14.3-32-31.99v-144H48c-17.67 0-32-14.32-32-32.01s14.33-31.99 32-31.99H192v-144c0-17.69 14.33-32.01 32-32.01s32 14.32 32 32.01v144h144C417.7 224 432 238.3 432 256z">
              </path>
            </svg>
            <span>Arbeitszeit hinzufügen ›</span>
          </a>
        </p>
      </div>

      <div class="content-wrap">
        <h3>Fehltage</h3>
        <div v-if="absence_days && absence_days.length == 0" class="alert alert-info" style="margin-bottom:0">
          <p>Noch keine Fehltage hinterlegt.</p>
        </div>
        <div v-else>
          <ul class="content-list">
            <li v-for="absence_day in absence_days" v-bind:key="absence_day.id">
              {{ absence_day.absence_day_formatted }}

              <svg @click="delete_absence_day(absence_day)" aria-hidden="true" focusable="false" data-prefix="fas"
                data-icon="xmark" class="svg-inline--fa fa-xmark" role="img" xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 320 512">
                <path fill="currentColor"
                  d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z">
                </path>
              </svg>
            </li>
          </ul>
        </div>
        <p style="text-align:right;margin-bottom:0">
          <a @click="modal_add_absence_day = true" class="button button-red">
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="plus" class="svg-inline--fa fa-plus"
              role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <path fill="currentColor"
                d="M432 256c0 17.69-14.33 32.01-32 32.01H256v144c0 17.69-14.33 31.99-32 31.99s-32-14.3-32-31.99v-144H48c-17.67 0-32-14.32-32-32.01s14.33-31.99 32-31.99H192v-144c0-17.69 14.33-32.01 32-32.01s32 14.32 32 32.01v144h144C417.7 224 432 238.3 432 256z">
              </path>
            </svg>
            <span>Fehltag hinzufügen ›</span>
          </a>
        </p>
      </div>
    </div>

    <vue-final-modal classes="modal-wrap" content-class="modal-inner" v-model="modal_add_working_hour">
      <svg @click="modal_add_working_hour = false" aria-hidden="true" focusable="false" data-prefix="far"
        data-icon="times-circle" class="modal-close svg-inline--fa fa-times-circle fa-w-16" role="img"
        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path fill="currentColor"
          d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm101.8-262.2L295.6 256l62.2 62.2c4.7 4.7 4.7 12.3 0 17l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L256 295.6l-62.2 62.2c-4.7 4.7-12.3 4.7-17 0l-22.6-22.6c-4.7-4.7-4.7-12.3 0-17l62.2-62.2-62.2-62.2c-4.7-4.7-4.7-12.3 0-17l22.6-22.6c4.7-4.7 12.3-4.7 17 0l62.2 62.2 62.2-62.2c4.7-4.7 12.3-4.7 17 0l22.6 22.6c4.7 4.7 4.7 12.3 0 17z">
        </path>
      </svg>
      <div class="modal-head">
        <h3>Arbeitszeit hinzufügen</h3>
      </div>
      <div class="modal-body">

        <div class="form-wrap select-wrap">
          <label for="working_day">Wochentag</label>
          <select v-model="working_hour.working_day" class="form-input">
            <option key="monday" value="monday">Montag</option>
            <option key="tuesday" value="tuesday">Dienstag</option>
            <option key="wednesday" value="wednesday">Mittwoch</option>
            <option key="thursday" value="thursday">Donnerstag</option>
            <option key="friday" value="friday">Freitag</option>
            <option key="saturday" value="saturday">Samstag</option>
            <option key="sunday" value="sunday">Sonntag</option>
          </select>
        </div>

        <div class="row row-gutter-20">
          <div class="col-12">
            <div class="form-wrap">
              <label for="start" class="form-label">Von</label>
              <input v-model="working_hour.start" class="form-input input-grey" type="time" name="start" id="start">
            </div>
          </div>
          <div class="col-12">
            <div class="form-wrap">
              <label for="end" class="form-label">Bis</label>
              <input v-model="working_hour.end" class="form-input input-grey" type="time" name="end" id="end">
            </div>
          </div>
        </div>

      </div>
      <div class="modal-meta">
        <a @click="create_working_hour" class="button button-red button-100">Arbeitszeit erstellen ›</a>
      </div>
    </vue-final-modal>

    <vue-final-modal classes="modal-wrap" content-class="modal-inner" v-model="modal_add_absence_day">
      <svg @click="modal_add_absence_day = false" aria-hidden="true" focusable="false" data-prefix="far"
        data-icon="times-circle" class="modal-close svg-inline--fa fa-times-circle fa-w-16" role="img"
        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path fill="currentColor"
          d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm101.8-262.2L295.6 256l62.2 62.2c4.7 4.7 4.7 12.3 0 17l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L256 295.6l-62.2 62.2c-4.7 4.7-12.3 4.7-17 0l-22.6-22.6c-4.7-4.7-4.7-12.3 0-17l62.2-62.2-62.2-62.2c-4.7-4.7-4.7-12.3 0-17l22.6-22.6c4.7-4.7 12.3-4.7 17 0l62.2 62.2 62.2-62.2c4.7-4.7 12.3-4.7 17 0l22.6 22.6c4.7 4.7 4.7 12.3 0 17z">
        </path>
      </svg>
      <div class="modal-head">
        <h3>Fehltag hinzufügen</h3>
      </div>
      <div class="modal-body" style="overflow-y:visible">

        <div class="form-wrap">
          <label for="absence_day" class="form-label">Bitte den Tag auswählen:</label>
          <v-date-picker v-model="absence_day.absence_day" :first-day-of-week="2" locale="de" color="red">
            <template v-slot="{ inputValue, inputEvents }">
              <input class="bg-white border px-2 py-1 rounded form-input" :value="inputValue" v-on="inputEvents" />
            </template>
          </v-date-picker>
        </div>

        <p>... oder, Zeitraum:</p>

        <div class="row row-gutter-20">
          <div class="col-12">
            <div class="form-wrap">
              <label for="absence_day" class="form-label">Bitte den Starttag auswählen:</label>
              <v-date-picker v-model="absence_day.absence_day_start" :first-day-of-week="2" locale="de" color="red">
                <template v-slot="{ inputValue, inputEvents }">
                  <input class="bg-white border px-2 py-1 rounded form-input" :value="inputValue" v-on="inputEvents" />
                </template>
              </v-date-picker>
            </div>
          </div>
          <div class="col-12">
            <div class="form-wrap">
              <label for="absence_day" class="form-label">Bitte den Endtag auswählen:</label>
              <v-date-picker v-model="absence_day.absence_day_end" :first-day-of-week="2" locale="de" color="red">
                <template v-slot="{ inputValue, inputEvents }">
                  <input class="bg-white border px-2 py-1 rounded form-input" :value="inputValue" v-on="inputEvents" />
                </template>
              </v-date-picker>
            </div>
          </div>
        </div>

      </div>
      <div class="modal-meta">
        <a @click="create_absence_day" class="button button-red button-100">Fehltag erstellen ›</a>
      </div>
    </vue-final-modal>
  </div>
</template>

<script>
export default {
  name: 'admin_user',
  data() {
    return {
      loading: true,
      modal_add_working_hour: false,
      modal_add_absence_day: false,
      working_hours: [],
      working_hour: {},
      absence_days: [],
      absence_day: {},
      user: {},
      password: null,
      password_confirmation: null
    }
  },
  methods: {
    async get_data() {
      this.password = null;
      this.password_confirmation = null;
      await this.$http.get(process.env.VUE_APP_BASE_API + '/admin/users/self', { headers: { Authorization: this.$store.getters.get_token } })
        .then(response => {
          this.user = response.data.user;
        })
      await this.$http.get(process.env.VUE_APP_BASE_API + '/admin/users/' + this.user.id + '/working_hours', { headers: { Authorization: this.$store.getters.get_token } })
        .then(response => {
          this.working_hours = response.data.working_hours;
        })
      await this.$http.get(process.env.VUE_APP_BASE_API + '/admin/users/' + this.user.id + '/absence_days', { headers: { Authorization: this.$store.getters.get_token } })
        .then(response => {
          this.absence_days = response.data.absence_days;
        })
      this.loading = false
    },
    update_user() {
      this.$http.patch(process.env.VUE_APP_BASE_API + '/admin/users/' + this.user.id, this.user, { headers: { Authorization: this.$store.getters.get_token } })
        .then(() => {
          this.get_data();
          this.$notify({
            title: "Erfolgreich gespeichert.",
            type: "success"
          });
        })
        .catch(error => {
          error.response.data.errors.forEach(value => {
            this.$notify({
              title: value,
              type: "error"
            });
          });
        });
    },
    update_password() {
      if (!this.password) {
        this.$notify({
          title: "Bitte Passwort ausfüllen.",
          type: "error"
        });
      } else if (this.user.password == this.user.password_confirmation) {
        this.$http.patch(process.env.VUE_APP_BASE_API + '/admin/users/' + this.user.id, {
          password: this.password,
          password_confirmation: this.password_confirmation
        }, { headers: { Authorization: this.$store.getters.get_token } })
          .then(() => {
            this.get_data();
            this.$notify({
              title: "Erfolgreich gespeichert.",
              type: "success"
            });
          })
          .catch(error => {
            error.response.data.errors.forEach(value => {
              this.$notify({
                title: value,
                type: "error"
              });
            });
          });
      } else {
        this.$notify({
          title: "Passwort stimmt nicht überein.",
          type: "error"
        });
      }
    },
    create_working_hour() {
      this.$http.post(process.env.VUE_APP_BASE_API + '/admin/users/' + this.user.id + '/working_hours', this.working_hour, { headers: { Authorization: this.$store.getters.get_token } })
        .then(() => {
          this.get_data();
          this.modal_add_working_hour = false;
          this.working_hour = {};
          this.$notify({
            title: "Erfolgreich erstellt.",
            type: "success"
          });
        })
        .catch(error => {
          error.response.data.errors.forEach(value => {
            this.$notify({
              title: value,
              type: "error"
            });
          });
        });
    },
    create_absence_day() {
      this.$http.post(process.env.VUE_APP_BASE_API + '/admin/users/' + this.user.id + '/absence_days', this.absence_day, { headers: { Authorization: this.$store.getters.get_token } })
        .then(() => {
          this.get_data();
          this.modal_add_absence_day = false;
          this.absence_day = {};
          this.$notify({
            title: "Erfolgreich erstellt.",
            type: "success"
          });
        })
        .catch(error => {
          error.response.data.errors.forEach(value => {
            this.$notify({
              title: value,
              type: "error"
            });
          });
        });
    },
    delete_hour(hour) {
      if (confirm("Wirklich löschen?")) {
        this.$http.delete(process.env.VUE_APP_BASE_API + '/admin/users/' + this.user.id + '/working_hours/' + hour.id, { headers: { Authorization: this.$store.getters.get_token } })
          .then(() => {
            this.get_data();
            this.$notify({
              title: "Erfolgreich gelöscht.",
              type: "success"
            });
          })
          .catch(error => {
            error.response.data.errors.forEach(value => {
              this.$notify({
                title: value,
                type: "error"
              });
            });
          });
      }
    },
    delete_absence_day(absence_day) {
      if (confirm("Wirklich löschen?")) {
        this.$http.delete(process.env.VUE_APP_BASE_API + '/admin/users/' + this.user.id + '/absence_days/' + absence_day.id, { headers: { Authorization: this.$store.getters.get_token } })
          .then(() => {
            this.get_data();
            this.$notify({
              title: "Erfolgreich gelöscht.",
              type: "success"
            });
          })
          .catch(error => {
            error.response.data.errors.forEach(value => {
              this.$notify({
                title: value,
                type: "error"
              });
            });
          });
      }
    },
  },
  mounted() {
    this.get_data();
  }
}
</script>
